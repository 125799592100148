import React from "react";
import { PageLayout, Background } from "@bluefin/components";
import { Grid, Image, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class FindStorePage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"find-store-hero"}
            />
          }
          subfooter={false}
          className={"find-store-page"}
        >
          <div className={"merchandise-item"}>
            <h4 />
            <Grid
              stackable={true}
              className={"component-section-container"}
              columns={"equal"}
              textAlign={"center"}
            >
              <Grid.Column>
                <Image
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/keeskitchen/image-26-11-21-12-21.jpeg"
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/keeskitchen/image-26-11-21-12-22.jpeg"
                  }
                />
              </Grid.Column>
            </Grid>
          </div>
          <Grid
            stackable={true}
            className={"component-section-container"}
            textAlign={"center"}
            padded={"vertically"}
          >
            <Grid.Column width={"14"}>
              <p>
                You can pick up a bottle of Kees Kitchen BBQ Sauce at the
                following locations:
              </p>
              <div className={"store-location"}>
                <List
                  items={[
                    "Piggly Wiggly",
                    "8780A Rivers Ave",
                    "North Charleston, SC 29406",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Piggly Wiggly",
                    "8508 Old State Rd",
                    "Holly Hill, SC 29059",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Piggly Wiggly",
                    "5583 S-18-200",
                    "Saint George, SC 29477",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Piggly Wiggly",
                    "630 Skylark Drive",
                    "Charleston SC 29407",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Burbage Meats",
                    "1033 Wappoo Rd Unit A",
                    "Charleston SC 2940",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Smoke N Brew",
                    "815 Folly Rd",
                    "Charleston, SC 29412",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "The Hideout",
                    "1006 Folly Rd",
                    "Charleston SC 29412",
                  ]}
                />
              </div>
              <div className={"store-location"}>
                <List
                  items={[
                    "Odyssey Bottle Shop",
                    "987 Harbor View Rd",
                    "Charleston, SC 29412",
                  ]}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
